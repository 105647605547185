<template lang="pug">
.bg-blue-50.mt-40.mb-10.relative.overflow-hidden.overflow-x-hidden
  .gradient-hiden
  .container
    .box-templates
      .col-1
        h2.text-gray-600.mb-5
          span.text-lg.block.mb-2 {{ $t('homePage.cta_title_1') }}
          span.font-bold.text-4xl.block.text-indigo-600 {{ $t('homePage.cta_title_2') }}
        .box-button
          .action-button
            router-link(:to="{ name: 'builder/select-template' }") {{ $t('homePage.cta_button') }}
      .col-2
        .template
          AtomsBasePictureAtom(:id_image="13")
        .template
          AtomsBasePictureAtom(:id_image="8")
        .template
          AtomsBasePictureAtom(:id_image="11")
        .template
          AtomsBasePictureAtom(:id_image="6")
        .template
          AtomsBasePictureAtom(:id_image="10")
        .template
          AtomsBasePictureAtom(:id_image="14")
</template>
<script>
export default {
  name: 'sectionCTA'
};
</script>
<style lang="scss" scoped>
.gradient-hiden {
  @apply absolute bg-gradient-to-r from-transparent to-gray-50 hidden md:block;
  width: 60px;
  height: 100%;
  right: 0;
  top: 0;
}

.col-1 {
  @apply flex flex-col flex-shrink-0 flex-grow w-full text-center md:max-w-sm md:text-left;
}
.col-2 {
  @apply flex-wrap gap-6 items-center justify-center hidden sm:flex md:flex-nowrap;
}

.box-templates {
  @apply flex flex-col py-12 gap-8 items-start md:flex-row md:items-center;
}

.template {
  @apply w-52 h-72 overflow-hidden rounded-md shadow-lg flex-shrink;
}

.box-button {
  @apply flex justify-center md:justify-start;
  .action-button {
    @apply px-6 py-2 bg-indigo-400 text-white font-bold text-lg rounded-md cursor-pointer;
  }
}
</style>
